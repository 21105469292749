<template>
  <div class="tabbar p_f">
    <div class="home cur_p" @click="$router.push('/')">
      <img src="../assets/img/home_ac.png" alt="" v-if="num == 1">
      <img src="../assets/img/home_d.png" alt="" v-else>
      <div class="text" :class="num == 1 ? 'ac' : ''">首页</div>
    </div>
    <div class="home cur_p" @click="$router.push('/guide')">
      <img src="../assets/img/dy_ac.png" alt="" v-if="num == 2">
      <img src="../assets/img/dy_d.png" alt="" v-else>
      <div class="text" :class="num == 2 ? 'ac' : ''">导游导览</div>
    </div>
    <!--<div class="home cur_p" @click="$router.push('/')">
      <img src="../assets/img/yj_ac.png" alt="" v-if="num == 3">
      <img src="../assets/img/yj_d.png" alt="" v-else>
      <div class="text" :class="num == 3 ? 'ac' : ''">游记</div>
    </div>-->
    <div class="home cur_p" @click="$router.push('/my')">
      <img src="../assets/img/mine_ac.png" alt="" v-if="num == 4">
      <img src="../assets/img/mime_d.png" alt="" v-else>
      <div class="text" :class="num == 4 ? 'ac' : ''">我的</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "tabbar",
    props: {
      num: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped lang="scss">
  .tabbar{
    img{
      height: 25px;
      padding-bottom: 2px;
    }
    .text{
      font-size: 15px;
      color: #4E4E4E;
      &.ac{
        font-weight: bold;
        color:  #1DDF50 ;
      }
    }
    .home{
      height: 100%;
      width: 33.3%;
      display: inline-block;
      font-size: 0;
    }
    box-sizing: border-box;
    padding: 8px 0 9px 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px -1px 3px 0px rgba(47, 48, 48, 0.2);
    z-index: 999;
  }
</style>
