<template>
  <div class="container t_l" ref="container">
    <div class="top_banner p_r" v-if="banner1.length">
      <div class="tb_out p_f d_f ali_c" :style="colorState?'background-color:#3BD367;':''">
        <div class="tbo_w_out d_f ali_c cur_p" @click="$router.push('/weather')">
          <div class="tw_icon">
            <img :src="today.httpsiconsiteday2" alt="">
          </div>
          <div class="flex_1">
            <div class="d_f ali_c j_s">
              <span class="tw_text text_over">{{nowName}}</span>
              <img class="tw_address" src="../assets/img/h_address.png" alt="">
            </div>
            <div class="d_f ali_c j_s">
              <span class="tw_w">{{today.cur_temp}}</span>
              <span class="tw_w">{{today.weather_day}}</span>
            </div>
          </div>
        </div>
        <div class="tbo_i_out d_f ali_c cur_p" @click="$router.push('/search')">
          <img src="../assets/img/h_t_search.png" alt="">
          <input type="text" class="flex_1" placeholder="搜索目的地/酒店/景点">
        </div>
        <div class="tbo_m_out cur_p" @click="$router.push('/message')">
          <div class="p_r">
            <img class="full" src="../assets/img/h_msg.png" alt="">
            <div class="red p_a"></div>
          </div>
        </div>

        <!--切换地区气泡-->
        <div class="p_a d_f ali_c top_bobo j_s" v-if="areaPop">
          <img src="../assets/img/h_address.png" alt="">
          <span class="flex_1">定位显示你在“<span>{{mapName}}</span>”</span>
          <div class="bobo_btn t_a cur_p" @click="changeArea">切换到“<span>{{mapName}}</span>”</div>
        </div>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in banner1" :key="index">
            <img :src="item.imgUrl" alt="" class="full" @click="banner1Click(item)">
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="h_content p_r">
      <div class="grey_cut top_card_out">
        <div class="tt_card p_r" v-if="tc_swiperMenu.length">
          <div v-for="(item,index) in tc_swiperMenu" :key="index" @click="banner1Click(item,null,'1')">
            <img :src="item.icon" alt="">
            <div class="name_tc2">{{item.name}}</div>
          </div>
        </div>
        <div class="t_c2" v-if="t_c2Arr.length">
          <div class="t_a" v-for="(item,index) in t_c2Arr" :key="index" @click="banner1Click(item,null,'1')">
            <img :src="item.icon" alt="">
            <div class="name_tc2">{{item.name}}</div>
          </div>
        </div>
        <div class="t_c3" v-if="t_c3Banner.length">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in t_c3Banner" :key="index">
              <img :src="item.imgUrl" alt="" class="full" @click="banner1Click(item,null,'1')">
            </div>
          </div>
        </div>
      </div>
      <!--热销产品-->
      <div class="grey_cut rxcp" v-if="HotCommodityArr.length">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l d_f ali_c">
            <div>热销产品</div>
            <div class="ti_bg">
              群众的眼睛是雪亮的
            </div>
          </div>
        </div>
        <div class="cp_item">
          <div class="p_r itm_out" v-if="HotCommodityArr[0]" @click="goTickDetail(0)">
            <div class="tag p_a t_a">
              <div>HOT</div>
              <div>1</div>
            </div>
            <img class="full" :src="HotCommodityArr[0].cover" alt="">
            <div class="cp_bo p_a">
              <div class="p_a">
                <div class="tip_tag" v-if="HotCommodityArr[0].remark">{{HotCommodityArr[0].remark}}</div>
                <div class="d_f ali_b j_s">
                  <div class="itm_title flex_1 text_over">{{HotCommodityArr[0].name}}</div>
                  <div class="itm_pri"><span>￥</span><span class="num">{{HotCommodityArr[0].sellPrice ? HotCommodityArr[0].sellPrice/100 : 0}}</span><span>起</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="p_r itm_out" v-if="HotCommodityArr[1]" @click="goTickDetail(1)">
            <div class="tag p_a t_a">
              <div>HOT</div>
              <div>2</div>
            </div>
            <img class="full" :src="HotCommodityArr[1].cover" alt="">
            <div class="cp_bo p_a">
              <div class="p_a">
                <div class="full_w text_over tip_tag" v-if="HotCommodityArr[1].remark">{{HotCommodityArr[1].remark}}</div>
                <div class="d_f ali_b j_s">
                  <div class="itm_title flex_1 text_over">{{HotCommodityArr[1].name}}</div>
                  <div class="itm_pri"><span>￥</span><span class="num">{{HotCommodityArr[1].sellPrice ? HotCommodityArr[1].sellPrice/100 : 0}}</span><span>起</span></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="p_r itm_out" v-if="HotCommodityArr[2]" @click="goTickDetail(2)">
              <div class="tag p_a t_a">
                <div>HOT</div>
                <div>3</div>
              </div>
              <img class="full" :src="HotCommodityArr[2].cover" alt="">
              <div class="cp_bo p_a">
                <div class="p_a">
                  <div class="text_over tip_tag no_mb" v-if="HotCommodityArr[2].remark">{{HotCommodityArr[2].remark}}</div>
                  <div class="d_f ali_b j_s">
                    <div class="itm_title flex_1 text_over">{{HotCommodityArr[2].name}}</div>
                    <div class="itm_pri"><span>￥</span><span class="num">{{HotCommodityArr[2].sellPrice ? HotCommodityArr[2].sellPrice/100 : 0}}</span><span>起</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p_r itm_out" v-if="HotCommodityArr[3]" @click="goTickDetail(3)">
              <div class="tag p_a t_a">
                <div>HOT</div>
                <div>4</div>
              </div>

              <img class="full" :src="HotCommodityArr[3].cover" alt="">
              <div class="cp_bo p_a">
                <div class="p_a">
                  <div class="text_over tip_tag no_mb" v-if="HotCommodityArr[3].remark">{{HotCommodityArr[3].remark}}</div>
                  <div class="d_f ali_b j_s">
                    <div class="itm_title flex_1 text_over">{{HotCommodityArr[3].name}}</div>
                    <div class="itm_pri"><span>￥</span><span class="num">{{HotCommodityArr[3].sellPrice ? HotCommodityArr[3].sellPrice/100 : 0}}</span><span>起</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--特惠产品  这儿要对接电商的跳转-->
      <div class="grey_cut zhyls_out" v-if="OddssaleCommodityArr.length">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l d_f ali_c">
            <div>特惠产品</div>
            <div class="ti_bg">
              每一次都是最优惠
            </div>
          </div>
          <div class="d_f ali_c cur_p" @click="goToMall('/product/list?productTagId=102')">
            <span>更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="zo_out">
          <div class="d_i" v-for="(item, index) in OddssaleCommodityArr" :key="index" @click="goToMall(item.outurl)">
            <van-image class="full" fit="cover" :src="item.cover" />
            <div class="zo_title t_l">{{item.name}}</div>
            <div class="d_f ali_b">
              <span>￥</span><span class="num">{{item.sellPrice ? item.sellPrice/100 : 0}}</span><span>起</span>
            </div>
          </div>
        </div>
      </div>
      <!--酒店推荐-->
      <div class="grey_cut leshan" v-if="discoveryArr.length">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l d_f ali_c">
            <div>酒店推荐</div>
            <div class="ti_bg">
              每一晚都是回家
            </div>
          </div>
          <div class="d_f ali_c cur_p" @click="$router.push({path:'/hotels'})">
            <span>更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="leshan_out">
          <div class="swiper-wrapper">
            <div class="swiper-slide cur_p" v-for="(item, index) in discoveryArr" @click="$router.push('/hotelInner?sellerId=' + item.sellerId)" :key="index">
              <van-image fit="cover" :src="item.cover" />
              <div class="le_out">
                <div class="p_a">
                  <div class="le_c t_l text_over">{{item.hotelName}}</div>
                  <div class="d_f ali_c addr">
                    <img class="le_v_icon" src="../assets/img/h_jd_addr.png" alt="">
                    <span>{{item.address}}</span>
                  </div>
                  <div class="d_f ali_c le_pr">
                    <span>价格</span>
                    <span><span>￥ </span><span class="num"> {{item.minPirce ? item.minPirce/100 : '0'}} </span></span>
                    <span> 起</span>
                    <div class="le_tag t_l" v-if="item.distance">
                      <div class="d_i text_over">距您{{item.distance}}公里</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--组合套餐-->
      <div class="grey_cut zhtc" v-if="ComposeCommodity.length">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l d_f ali_c">
            <div>组合套餐</div>
            <div class="ti_bg">
              省心省力玩开心
            </div>
          </div>
          <div class="d_f ali_c cur_p" @click="$router.push({path:'/lineJp'})">
            <span>更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="p_r card_out" @click="gotoLine(ComposeCommodity[acNum])">
          <div class="d_f ali_c j_s p_r">
            <div class="card_swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in ComposeCommodity" :key="index">
                  <img class="prrImg" :src="item.cover" alt="">
                  <!-- <van-image fit="cover" :src="item.cover"></van-image> -->
                  <div class="s_title text_over">{{ item.name }}</div>
                  <div class="s_tag_out" v-for="(itm,idx) in item.tags.split(',')" :key="idx">
                    <div class="d_i" v-if="item.tags">{{itm}}</div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev btn btnl" @click.stop="nextEl" />
              <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
              <div class="swiper-button-next btn btnr" @click.stop="prevEl" />
              <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
            </div>
            <div class="co_ri">
              <div class="cr_title">
                {{ComposeCommodity[acNum].name}}
              </div>
              <div class="cr_bor">
                <div class="crb_ti">-<span>套餐说明</span>-</div>
                <div class="crb_detail">
                  {{ComposeCommodity[acNum].remark}}
                </div>
              </div>
            </div>
          </div>
          <div class="d_f ali_c co_b_out">
            <span class="pri">￥<span class="num">{{ComposeCommodity[acNum].sellPrice ? ComposeCommodity[acNum].sellPrice/100 : 0}}</span></span>
            <span class="sub_pri flex_1">￥<span>{{ComposeCommodity[acNum].shopPrice ? ComposeCommodity[acNum].shopPrice/100 : 0}}</span></span>
            <div class="co_del_btn cur_p d_f ali_c j_c">
              <span>套餐详情</span>
              <img src="../assets/img/h_zh_ri.png" alt="">
            </div>
          </div>
        </div>
      </div>

      <!--旅游伴手礼-->
      <div class="grey_cut lvbsl" v-if="bslBanner.length">
        <div class="title d_f ali_b">
          <div class="flex_1 t_l d_f ali_c">
            <div>旅游伴手礼</div>
            <div class="ti_bg">
              空手而归总是遗憾
            </div>
          </div>
          <div class="d_f ali_c cur_p" @click="goToMall('/')">
            <span>更多</span>
            <img src="../assets/img/see_more.png" alt="">
          </div>
        </div>
        <div class="item_out d_f ali_c j_s">
          <div class="p_r item_detail id_1" v-if="bslBanner.length>0" @click="goToMall(bslBanner[0].interlinkage)">
            <van-image fit="cover" :src="bslBanner[0].imgUrl"></van-image>
            <div class="p_a name">{{bslBanner[0].name || bslBanner[0].remark}}</div>
          </div>
          <div class="flex_1">
            <div class="p_r item_detail id_2" v-if="bslBanner.length>1" @click="goToMall(bslBanner[1].interlinkage)">
              <van-image fit="cover" :src="bslBanner[1].imgUrl"></van-image>
              <div class="p_a name">{{bslBanner[1].name || bslBanner[1].remark}}</div>
            </div>
            <div class="p_r item_detail id_3" v-if="bslBanner.length>2" @click="goToMall(bslBanner[2].interlinkage)">
              <van-image fit="cover" :src="bslBanner[2].imgUrl"></van-image>
              <div class="p_a name">{{bslBanner[2].name || bslBanner[2].remark}}</div>
            </div>
            <div class="p_r item_detail id_4" v-if="bslBanner.length>3" @click="goToMall(bslBanner[3].interlinkage)">
              <van-image fit="cover" :src="bslBanner[3].imgUrl"></van-image>
              <div class="p_a name">{{bslBanner[3].name || bslBanner[3].remark}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <tabbar :num="1"></tabbar>
    <div class="p_f d_f ali_c j_c loading_pop" v-if="isLoading">
      <van-loading />
    </div>
    <div class="p_f d_f ali_c j_c loading_pop tip_pop" v-if="showTip && tipInfo && tipInfo.title">
      <div class="tp_out p_r">
        <div class="tp_close p_a cur_p" @click="closeTipPop">
          <img src="../assets/img/h_tp_close.png" alt="">
        </div>
        <div class="tp_red t_a">{{tipInfo.tags}}</div>
        <div class="tp_top">
          <div class="tp_title t_a">{{tipInfo.title}}</div>
          <div class="d_f ali_c j_s">
            <span>发布时间：<span>{{new Date(tipInfo.createDate).toLocaleDateString()}}</span></span>
            <span>发布源：<span>{{tipInfo.source}}</span></span>
          </div>
        </div>
        <div class="tp_content" v-html="tipInfo.content"></div>
        <div class="tp_btn cur_p d_f ali_c j_c" @click="closeTipPop">我已知晓</div>
      </div>
    </div>
  </div>
</template>
<script>
import tabbar from '../components/tabbar';
// import wx from 'weixin-js-sdk';//引入wxsdk

import '../../public/swiper/swiper.min.css';
import Swiper from '../../public/swiper/swiper.min.js';
import PinYin from '../util/pinyin/vPInYin';
import api from '../api/index';
import apis from '../views/hotel/utils/apis';
import { Toast, Image, Popup } from 'vant';
import axios from 'axios';
import * as echarts from 'echarts';
export default {
    name: 'HelloWorld',
    watch: {
        areaPop(n, o) {
            let _t = this;
            if (n) {
                setTimeout(function () {
                    _t.areaPop = false;
                    localStorage.setItem('areaPop', 1);
                }, 10000);
            }
        },
    },
    components: {
        'van-image': Image,
        'van-popup': Popup,
        tabbar,
    },
    data() {
        return {
            showTip: false,
            colorState: false,
            isLoading: false,
            areaPop: false,
            alive: true,
            nowCity: '',
            nowName: '',
            mapName: '',
            today: {},
            banner1: [],
            cityCode: '',
            tc_swiperMenu: [],
            t_c2Arr: [],
            vrArr: [],
            discoveryArr: [],
            t_c3Banner: [],
            bslBanner: [],
            otherArr: [],
            HotCommodityArr: [],
            ComposeCommodity: [],
            OddssaleCommodityArr: [],
            acNum: 0,
            urlWX: '', //获取签名的url地址
            tipInfo: null,
        };
    },
    created() {
        this.getShareInfo(
            this.$global.shareTitle,
            this.$global.visitUrl,
            this.$global.shareImage,
            this.$global.shareIntro
        );
        /*this.getShareInfo(
        '智游乐山',
        this.$global.visitUrl,
        'https://zyls-test2.oss-cn-chengdu.aliyuncs.com/峨眉山266d198a8-b1d6-4c96-81dd-afd28af1fb45.jpg',
        '智游乐山'
      );*/
        // this.isLoading = true;
        this.slicedQueryVideo(); //酒店推荐
        this.queryHotCommodity();
        this.queryComposeCommodity();
        this.queryOddssaleCommodity();
        if (!sessionStorage.getItem('closePop')) {
            this.findByColumnCode();
        }
        this.queryBanner(17); //顶部banner

        this.getAppMenu('sycd'); //顶部banner
        // this.getAppMenu('syzbcd');//首页中部菜单
        //判断是否存在定位
        let isArea = 0;
        if (localStorage.getItem('area')) {
            isArea = 1;
            this.nowCity = JSON.parse(localStorage.getItem('area')).name;
            this.cityCode = JSON.parse(localStorage.getItem('area')).cityCode;
        } else if (localStorage.getItem('map')) {
            this.nowCity = JSON.parse(
                localStorage.getItem('map')
            ).addressComponent.district;
        }
        this.weatherjson();

        this.$nextTick(() => {
            this.changeColor();
            //判断是否存在定位
            let isArea = 0;
            if (localStorage.getItem('area')) {
                isArea = 1;
            }
            this.getAddress(isArea);
        });
    },
    methods: {
        closeTipPop() {
            this.showTip = false;
            sessionStorage.setItem('closePop', 1);
        },
        findByColumnCode() {
            api.findByColumnCode().then(res => {
                this.tipInfo = res.data;
                if (this.tipInfo) {
                    if (this.tipInfo.title) {
                        this.showTip = true;
                    }
                }
            });
        },
        goTickDetail(num) {
            let row = this.HotCommodityArr[num];
            this.$router.push(
                '/tiketDetails/' + row.sellerId + '?priceCalendarCount=1'
            );
        },
        goToMall(path) {
          // 没有 path
            let url =
                this.$global.baseMallUrl +
                this.$global.token +
                '&redirectUrl=' +
                encodeURIComponent(path);
            window.location.href = url;
        },
        // 组合套餐跳转
        gotoLine(row) {
            this.$router.push({
                path: '/Combo',
                query: {
                    commodityId: row.commodityId,
                    sellerId: row.sellerId,
                },
            });
        },
        // 组合
        queryComposeCommodity() {
            let _t = this;
            api.queryComposeCommodity().then(res => {
                _t.ComposeCommodity = res.data;
                _t.$nextTick(() => {
                    this.sip();
                });
            });
        },
        sip() {
            let _t = this;
            new Swiper('.card_swiper', {
                effect: 'cards',
                grabCursor: true,
                loop: true,
                observer: true,
                observeParents: true,
                preventClicksPropagation: false, //阻止click冒泡。拖动Swiper时阻止click事件。
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChange: function () {
                        _t.acNum = this.realIndex; //切换结束时，告诉我现在是第几个slide
                    },
                    click: function (swiper, event) {
                        console.log(event);
                        event.stopPropagation();
                    },
                },
            });
        },
        // 热销商品
        queryHotCommodity() {
            api.queryHotCommodity().then(res => {
                this.HotCommodityArr = res.data;
            });
        },
        // 特惠商品
        queryOddssaleCommodity() {
            api.queryOddssaleCommodity().then(res => {
                this.OddssaleCommodityArr = res.data;
            });
        },

        //查询视频
        slicedQueryVideo() {
            this.$get({
                url: apis.searchHotelList,
                params: {
                    isRecommend: 1, //是否推荐
                    areaName: this.areaName,
                    sortName: 'sequence',
                    sortOrder: 'asc',
                    token: this.$global.token,
                    pageSize: 10,
                    pageIndex: this.pageIndex,
                    typeCode: 'jd',
                    homeShown: '1',
                },
            }).then(rsp => {
                this.discoveryArr = rsp.data;
                setTimeout(() => {
                    this.lsOutSwiper();
                }, 0);
            });
        },
        // 发现了山轮播
        lsOutSwiper() {
            this.$nextTick(() => {
                let leshan_out = new Swiper('.leshan_out', {
                    slidesPerView: 1.3,
                    spaceBetween: 29,
                    centeredSlides: true,
                    // loop: true,
                    freeMode: false,
                });
            });
        },
        // 菜单
        getAppMenu(code) {
            api.getAppMenuByWX().then(res => {
                let data = JSON.parse(JSON.stringify(res.data));
                let datas = JSON.parse(JSON.stringify(res.data));
                this.tc_swiperMenu = data.slice(0, 8);
                this.t_c2Arr = datas.slice(8, 12);
            });
        },
        //banner
        queryBanner(position, codes) {
            let code = this.cityCode
                ? this.cityCode
                : PinYin.chineseToPinYin(this.nowCity);
            code = position == 1 ? code : '';
            code = codes ? codes : code;
            api.queryBanner({ position: position, code: code }).then(res => {
                // api.queryBanner({position:position}).then(res => {
                let data = JSON.parse(JSON.stringify(res.data));
                if (position == 1) {
                    this.banner1 = data;
                    this.$nextTick(() => {
                        this.banner1Swiper();
                    });
                } else if (position == 2) {
                    this.t_c3Banner = data;
                    if (this.t_c3Banner.length > 1) {
                        this.$nextTick(() => {
                            this.bannert_c3Swiper();
                            /*setTimeout(() => {
                },500)*/
                        });
                    }
                } else if (position == 17) {
                    this.bslBanner = data;
                }
            });
        },
        bannert_c3Swiper() {
            if (this.t_c3Banner.length > 1) {
                let tc3 = new Swiper('.t_c3', {
                    // loop: true, // 循环模式选项
                    speed: 500, //切换时长
                    // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状。
                    autoplay: {
                        delay: 3000, //自动切换延时
                        // stopOnLastSlide: false, //如果设置为true，当切换到最后一个slide时停止自动切换。（loop模式下无效）。
                        // disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
                    },
                    // keyboard: true,  //键盘切换
                });
            }
        },
        // 顶部banner轮播
        banner1Swiper() {
            let mySwiper = new Swiper('.swiper-container', {
                loop: true, // 循环模式选项
                speed: 500, //切换时长
                // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状。
                autoplay: {
                    delay: 3000, //自动切换延时
                    stopOnLastSlide: false, //如果设置为true，当切换到最后一个slide时停止自动切换。（loop模式下无效）。
                    disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
                },
                keyboard: true, //键盘切换
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换。
                },
            });
        },
        // 点击顶部banner
        banner1Click(row, type, isOut, zh) {
            // xyhwcldnblj 内部跳转的remark匹配这个
            let url = row.outurl || row.interlinkage;
            if (url) {
                let out = false;
                if (type) out = true;
                if (isOut) {
                    if (row.remark.indexOf('xyhwcldnblj') != -1) {
                        out = false;
                    } else {
                        out = true;
                    }
                }
                if (out) {
                    if (!zh) {
                        if (url.indexOf('token') != -1) {
                            let backUrl = window.location.href;
                            window.location.href =
                                url +
                                this.$global.token +
                                '&backUrl=' +
                                backUrl;
                        } else {
                            window.location.href = url;
                        }
                    } else {
                        this.$router.push({
                            path: '/outWindow',
                            query: {
                                interlinkage: encodeURI(url),
                            },
                        });
                    }
                } else {
                    this.$router.push(row.interlinkage.split('#')[1]);
                }
            }
        },
        // 顶部气泡切换地区
        changeArea() {
            this.areaPop = false;
            localStorage.removeItem('area');
            this.cityCode = '';
            this.getAddress();
        },
        // 首页获取天气
        weatherjson(code) {
            this.queryBanner(1); //顶部banner
            this.queryBanner(2); //顶部banner
            this.isLoading = false;
            this.nowName = '峨眉山市';
            /*api.weatherjson(this.cityCode ? this.cityCode : PinYin.chineseToPinYin(this.nowCity)).then(res => {
          // api.weatherjson(this.cityCode ? this.cityCode : PinYin.chineseToPinYin('云阳县')).then(res => {
          this.nowName = res.weatherJson.name;
          this.today = JSON.parse(JSON.stringify(res.weatherJson.today));
          this.queryBanner(1);//顶部banner
          this.queryBanner(2);//顶部banner
          this.isLoading = false;
        }).catch(err => {
          Toast('为您跳转到峨眉山市');
          this.nowCity = '峨眉山市';
          this.weatherjson();
        })*/
        },
        // 头部颜色
        scrollFunc() {
            let top = this.$refs.container.scrollTop;
            if (top > 60) {
                this.colorState = true;
            } else {
                this.colorState = false;
            }
        },
        // 头部滑动变色事件
        changeColor() {
            let _t = this;
            // 通过$refs获取dom元素
            _t.box = _t.$refs.container;
            // 监听这个dom的scroll事件
            _t.box.addEventListener(
                'scroll',
                () => {
                    _t.scrollFunc();
                },
                false
            );
        },
        //获取定位信息
        getAddress(hasArea) {
            let _t = this;
            AMap.plugin('AMap.Geolocation', function () {
                var geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 10000,
                    // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                    buttonOffset: new AMap.Pixel(10, 20),
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,
                    //  定位按钮的排放位置,  RB表示右下
                    buttonPosition: 'RB',
                });

                geolocation.getCurrentPosition();
                AMap.event.addListener(geolocation, 'complete', onComplete);
                AMap.event.addListener(geolocation, 'error', onError);

                function onComplete(data) {
                    // data是具体的定位信息
                    if (data.addressComponent.city != '乐山市') {
                        _t.delFail(hasArea);
                    } else {
                        _t.nowCity = data.addressComponent.district;
                        _t.mapName = data.addressComponent.district;
                        localStorage.setItem('map', JSON.stringify(data));
                        if (!hasArea) {
                            _t.weatherjson();
                        } else {
                            _t.areaPop = localStorage.getItem('areaPop')
                                ? false
                                : true;
                        }
                    }
                }
                function onError(data) {
                    _t.delFail(hasArea, true);
                }
            });
        },
        delFail(hasArea, noTip) {
            let _t = this;
            _t.nowCity = '峨眉山市';
            _t.mapName = '峨眉山市';
            if (!hasArea) {
                _t.weatherjson();
            } else {
                let city = localStorage.getItem('map')
                    ? JSON.parse(localStorage.getItem('map')).addressComponent
                          .city
                    : '';
                if (city == '乐山市') {
                    _t.areaPop = localStorage.getItem('areaPop') ? false : true;
                }
            }
            if (noTip) {
                Toast('定位失败，为您跳转到峨眉山市');
            }
        },
    },
    mounted() {
        /*this.$nextTick(() => {
        this.changeColor();
        //判断是否存在定位
        console.log(12323)
        let isArea = 0;
        if(localStorage.getItem('area')){
          isArea = 1;
        }
        this.getAddress(isArea);
      })*/
    },
};
</script>
<style scoped lang="scss">
.tp_btn {
    width: 283px;
    height: 43px;
    background: #23d7cc;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
}
.tp_out {
    .tp_content {
        padding: 14px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
    }
    .tp_top {
        .d_f {
            margin-top: 11px;
            font-size: 10px;
            font-weight: 500;
            color: #333333;
        }
        border-bottom: 1px dotted #000000;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        padding: 0 26px 10px 26px;
    }
    .tp_red {
        width: 108px;
        height: 24px;
        background: #f96e6e;
        border-radius: 12px;
        font-size: 12px;
        line-height: 24px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 auto 20px auto;
    }
    .tp_close {
        top: 11px;
        right: 11px;
        width: 11px;
        height: 11px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    padding: 16px 0;
    box-sizing: border-box;
    width: 350px;
    background: #ffffff;
    border-radius: 10px;
}
.loading_pop {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.35);
}
.top_bobo {
    .bobo_btn {
        width: 125px;
        height: 33px;
        background: #0cd344;
        border-radius: 7px;
        line-height: 33px;
    }
    img {
        width: 9px;
        margin-right: 6px;
    }
    padding: 12px 10px 8px 10px;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    width: 340px;
    height: 53px;
    background: url('../assets/img/h_top_pop.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    top: 40px;
    left: 19px;
}
.h_content {
    .lvbsl {
        .item_out {
            .flex_1 {
                .item_detail {
                    .name {
                        width: 100%;
                        height: 19px;
                        line-height: 19px;
                        font-size: 12px;
                    }
                    height: 60px;
                    margin-bottom: 11px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .item_detail {
            &.id_1 {
                width: 200px;
                height: 200px;
                margin-right: 10px;
                .name {
                    width: 100%;
                    height: 36px;
                    line-height: 36px;
                    font-size: 15px;
                }
            }
            .name {
                left: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.3);
                text-align: center;
                font-weight: bold;
                color: #ffffff;
            }
            .van-image {
                width: 100%;
                height: 100%;
            }
            cursor: pointer;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
        }
        .title {
            .ti_bg {
                background: url('../assets/img/h_lv_bg.png') no-repeat center !important;
                background-size: 100% 100% !important;
            }
            margin-bottom: 22px;
        }
        padding-top: 22px !important;
    }
    .zhtc {
        .card_out {
            z-index: 1;
            .co_b_out {
                position: relative;
                top: -12px;
                .co_del_btn {
                    img {
                        margin-left: 9px;
                        width: 8px;
                        height: 14px;
                    }
                    width: 88px;
                    height: 36px;
                    background: linear-gradient(90deg, #ff8e5a, #fb5c5f);
                    border-radius: 18px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #ffffff;
                }
                .sub_pri {
                    font-size: 12px;
                    font-weight: bold;
                    color: #a2a2a2;
                    text-decoration: line-through;
                }
                .pri {
                    .num {
                        font-size: 18px;
                    }
                    font-size: 10px;
                    font-weight: bold;
                    color: #f96937;
                    margin-right: 10px;
                }
                width: 100%;
            }
            > .p_r {
                top: -40px;
            }
            .co_ri {
                position: relative;
                top: 17px;
                .cr_bor {
                    .crb_detail {
                        font-size: 10px;
                        font-weight: 400;
                        color: #8c8989;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .crb_ti {
                        text-align: center;
                        font-size: 12px;
                        font-weight: bold;
                        font-style: italic;
                        color: #f96937;
                        margin-bottom: 6px;
                    }
                    padding: 4px 6px 10px;
                    box-sizing: border-box;
                    width: 171px;
                    background: #fffcf3;
                    border: 1px solid #f96937;
                    border-radius: 10px;
                }
                .cr_title {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                    min-height: 31px;
                    margin-bottom: 11px;
                }
            }
            .card_swiper {
                z-index: 99;
                width: 131px;
                margin-right: 30px;
                --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
                --swiper-navigation-color: rgb(
                    59,
                    211,
                    103
                ); /* 单独设置按钮颜色 */
                --swiper-navigation-size: 12px; /* 设置按钮大小 */

                .btn {
                    &:focus {
                        outline: none;
                    }
                    top: 0;
                    width: 20%;
                    height: 100%;
                }
                .btnl {
                    // padding-right: 30%;
                }
                .btnr {
                    // padding-left: 30%;
                }
                .swiper-slide {
                    height: 165px;
                    .s_tag_out {
                        > div {
                            padding: 3px 4px;
                            background-color: rgba(255, 255, 255, 0.2);
                            margin-right: 2px;
                        }
                        font-size: 10px;
                        font-weight: 400;
                        color: #ffffff;
                    }
                    .s_title {
                        margin-bottom: 26px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #ffffff;
                    }
                    .prrImg {
                        width: 100%;
                        height: 72px;
                        border-radius: 10px 10px 0 0;
                        overflow: hidden;
                        margin-bottom: 8px;
                    }
                    width: 131px;
                    border-radius: 10px;
                    padding: 6px 6px 10px;
                    box-sizing: border-box;
                    background-color: #7e97b5;
                    &:nth-child(2n) {
                        background-color: #24538a;
                    }
                    &:nth-child(3n) {
                        background-color: #299d6d;
                    }
                }
            }
            padding: 16px 12px 0 9px;
            width: 100%;
            background: #ffffff;
            box-shadow: 0 2px 2px 0 rgba(6, 0, 1, 0.1);
            border-radius: 5px;
            margin-top: 50px;
            box-sizing: border-box;
        }
        box-sizing: border-box;
        .title {
            .ti_bg {
                background: url('../assets/img/h_zh_bg.png') no-repeat center !important;
                background-size: 100% 100% !important;
            }
            margin-bottom: 35px;
        }
        background-color: #f2f2f2;
        padding: 15px 13px 25px !important;
    }
    .leshan {
        .leshan_out {
            margin-top: 20px;
            .swiper-slide {
                .le_out {
                    .le_tag > div {
                        font-size: 10px;
                        font-weight: 400;
                        color: #ffffff;
                        padding: 2px 5px;
                        border-radius: 2px;
                        background: rgba(255, 255, 255, 0.4);
                        margin-left: 7px;
                    }
                    .le_pr {
                        .num {
                            font-size: 15px;
                            font-weight: bold;
                        }
                        font-size: 10px;
                        font-weight: 400;
                        color: #ffffff;
                    }
                    .le_v_icon {
                        width: 10px;
                        height: 10px;
                        margin-right: 4px;
                    }
                    .addr {
                        font-size: 10px;
                        font-weight: 400;
                        color: #ffffff;
                        margin-bottom: 6px;
                    }
                    .le_c {
                        font-size: 14px;
                        font-weight: bold;
                        color: #ffffff;
                        margin-bottom: 8px;
                    }
                    > .p_a {
                        padding: 9px 10px;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        box-sizing: border-box;
                    }
                    font-size: 15px;
                    font-weight: 400;
                    color: #3e3e3e;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 30%;
                    box-sizing: border-box;
                    background: linear-gradient(
                        rgba(186, 172, 255, 0),
                        rgba(58, 118, 160, 1)
                    );
                }
                .van-image {
                    height: 317px;
                }
                position: relative;
                width: 225px;
                /*height: 270px;*/
                background: #ffffff;
                box-shadow: 0 0 3px 2px rgba(164, 165, 165, 0.34);
                border-radius: 5px;
                overflow: hidden;
            }
        }
        .title {
            margin-bottom: 20px;
            padding: 0 13px;
            .ti_bg {
                background: url('../assets/img/h_jd_bg.png') no-repeat center !important;
                background-size: 100% 100% !important;
            }
        }
        overflow: hidden;
        padding: 20px 0 33px 0 !important;
    }
    .zhyls_out {
        .zo_out {
            > .d_i {
                .ali_b {
                    span {
                        font-size: 10px;
                        font-weight: 400;
                        color: #f96937;
                    }
                    .num {
                        font-size: 14px;
                    }
                    padding: 0 5px 8px;
                }
                .zo_title {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: 7px;
                    padding: 0 5px;
                }
                .van-image {
                    width: 100%;
                    margin-bottom: 8px;
                    height: 170px;
                    overflow: hidden;
                }

                width: 170px;
                background: #ffffff;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0 0 2px 2px #d8d8d8;
                margin-right: 10px;
            }
            white-space: nowrap;
            overflow-x: auto;
        }
        .title {
            margin-bottom: 23px;
            .ti_bg {
                background: url('../assets/img/h_th_bg.png') no-repeat center !important;
                background-size: 100% 100% !important;
            }
        }
        padding: 24px 13px 13px 19px !important;
    }
    .rxcp {
        .cp_item {
            .itm_out {
                .tip_tag {
                    padding: 2px 5px;
                    font-size: 10px;
                    font-weight: 500;
                    color: #ffffff;
                    border: 1px solid #ffffff;
                    display: inline-block;
                    margin-bottom: 5px;
                    box-sizing: border-box;
                    max-width: 100%;
                    &.no_mb {
                        margin-bottom: 0;
                    }
                }
                .cp_bo {
                    .itm_pri {
                        .num {
                            font-size: 14px;
                        }
                        font-size: 10px;
                        font-weight: 400;
                        color: #ffffff;
                    }
                    .itm_title {
                        font-size: 14px;
                        font-weight: bold;
                        color: #ffffff;
                    }
                    > .p_a {
                        width: 100%;
                        left: 0;
                        bottom: 0;
                        padding: 9px 10px;
                        box-sizing: border-box;
                    }
                    height: 50%;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(
                        rgba(186, 172, 255, 0),
                        rgba(40, 206, 200, 0.51)
                    );
                }
                /*padding: 12px 9px;*/
                border-radius: 5px;
                box-sizing: border-box;
                overflow: hidden;
            }
            > div {
                overflow: hidden;
                &:nth-child(1) {
                    width: 100%;
                    height: 180px;
                    margin-bottom: 9px;
                }
                &:nth-child(2) {
                    .cp_bo {
                        background: linear-gradient(
                            rgba(186, 172, 255, 0),
                            rgba(52, 176, 66, 0.51)
                        );
                        height: 30%;
                    }
                    width: 165px;
                    height: 190px;
                    margin-right: 8px;
                    display: inline-block;
                }
                &:nth-child(3) {
                    display: inline-block;
                    > div {
                        width: 175px;
                        height: 90px;
                        &:nth-child(1) {
                            margin-bottom: 10px;
                            .cp_bo {
                                background: linear-gradient(
                                    rgba(186, 172, 255, 0),
                                    rgba(25, 85, 220, 0.51)
                                );
                            }
                        }
                        &:nth-child(2) {
                            .cp_bo {
                                background: linear-gradient(
                                    rgba(186, 172, 255, 0),
                                    rgba(254, 124, 54, 0.51)
                                );
                            }
                        }
                    }
                }
            }
            .tag {
                width: 33px;
                height: 33px;
                background: url('../assets/img/h_cp_tag.png') no-repeat center;
                background-size: 100% 100%;
                font-size: 10px;
                padding-top: 3px;
                font-weight: bold;
                color: #ffffff;
                left: 0;
                top: 0;
            }
            margin-top: 19px;
            letter-spacing: 0;
            word-spacing: 0;
        }
        padding: 11px 13px 17px 13px !important;
    }
    .t_c3 {
        height: 104px;
        background-color: rgba(0, 122, 255, 0);
        border-radius: 3px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .t_c2 {
        div:not(.name_tc2) {
            &:first-child {
                background: url('../assets/img/h_or_bg1.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(2) {
                background: url('../assets/img/h_or_bg2.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(3) {
                background: url('../assets/img/h_or_bg3.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(4) {
                background: url('../assets/img/h_or_bg4.png') no-repeat center;
                background-size: 100% 100%;
            }
            display: inline-block;
            width: calc((100% - 6px) / 4) !important;
            margin-right: 2px;
            text-align: center;
            cursor: pointer;
            height: 50px;
            font-size: 12px;
            font-weight: 500;
            color: #ffffff;
            padding-top: 4px;
            box-sizing: border-box;
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        .name_tc2 {
            position: relative;
            top: -2px;
        }
        img {
            height: 20px;
            width: auto;
            margin-bottom: 5px;
        }
        margin: 6px auto 8px auto;
    }
    .tt_card {
        @extend .t_c2;
        div:not(.name_tc2) {
            &:nth-child(1) {
                background: url('../assets/img/h_g_bg1.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(2) {
                background: url('../assets/img/h_g_bg2.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(3) {
                background: url('../assets/img/h_g_bg3.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(4) {
                background: url('../assets/img/h_g_bg4.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(5) {
                background: url('../assets/img/h_g_bg5.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(6) {
                background: url('../assets/img/h_g_bg6.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(7) {
                background: url('../assets/img/h_g_bg7.png') no-repeat center;
                background-size: 100% 100%;
            }
            &:nth-child(8) {
                background: url('../assets/img/h_g_bg8.png') no-repeat center;
                background-size: 100% 100%;
            }
            margin-bottom: 2px;
            margin-top: 0;
        }
        margin: 0 auto;
    }
    .grey_cut {
        .title {
            .ti_bg {
                font-size: 10px;
                font-weight: 500;
                color: #ffffff;
                width: 123px;
                height: 15px;
                margin-left: 10px;
                line-height: 15px;
                padding-left: 17px;
                box-sizing: border-box;
                background: url('../assets/img/h_rx_bg.png') no-repeat center;
                background-size: 100% 100%;
            }
            .ali_c {
                img {
                    width: 6px;
                }
                span {
                    font-size: 13px;
                    font-weight: 500;
                    color: #4e4e4e;
                    margin-right: 6px;
                }
            }
            .flex_1 {
                font-size: 19px;
                font-weight: 800;
                color: #3e3e3e;
            }
        }
        &.top_card_out {
            padding-top: 21px;
        }
        padding: 0 13px 17px;
        border-bottom: 8px solid #f2f2f2;
    }
}
.top_banner {
    .tb_out {
        .tbo_m_out {
            .red {
                width: 5px;
                height: 5px;
                background: #d60707;
                border: 1px solid #ffffff;
                border-radius: 50%;
                right: -2px;
                top: -2px;
            }
            div {
                width: 21px;
                height: 19px;
                img {
                    vertical-align: super;
                }
            }
        }
        .tbo_i_out {
            input {
                border: none;
                outline: none;
                background-color: rgba(255, 255, 255, 0);
                color: white;
                font-size: 12px;
                &::placeholder {
                    color: white;
                }
            }
            img {
                width: 17px;
                height: 17px;
                margin-right: 12px;
            }
            padding: 0 10px;
            box-sizing: border-box;
            width: 236px;
            height: 29px;
            margin-right: 9px;
            background: url('../assets/img/h_i_bg.png') no-repeat;
            background-size: 100% 100%;
        }
        .tbo_w_out {
            .tw_address {
                width: 7px;
            }
            .tw_w {
                font-size: 9px;
            }
            .tw_text {
                font-size: 10px;
            }
            .tw_icon {
                > img {
                    height: 80%;
                    width: auto;
                    max-width: 80%;
                }
                font-size: 0;
                height: 20px;
                margin-right: 5px;
            }
            width: 68px;
            margin-right: 11px;
        }
        color: white;
        width: 100%;
        padding: 10px 15px 10px 15px;
        z-index: 999;
        background-color: rgba(255, 255, 255, 0);
    }
    .swiper-container {
        height: 239px;
    }
    .swiper-slide {
        img {
            width: 100%;
            height: 100%;
        }
        background-color: rgba(0, 122, 255, 0);
    }
    overflow: hidden;
}
.container {
    padding-bottom: 70px;
    background-color: white;
    overflow: auto;
    box-sizing: border-box;
}
</style>
